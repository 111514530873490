import React from "react";
import SearchBar from "../components/molecules/SearchBar";

const SearchPage: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full">
      <div className="flex flex-col items-center max-w-[1200px] w-full h-screen sm:h-full  py-20 mx-auto">
        <div className="space-y-4 px-5 w-full">
          <div className="max-w-[680px] mx-auto text-start space-y-[16px]">
            <h1 className="space-y-[8px] md:text-[40px] text-[24px] md:leading-[48px] leading-[32px] text-BCP-dark-blue">
              <div className={`font-[400]`}>Hola,</div>
              <div className={`font-bold`}>¿en qué te puedo ayudar?</div>
            </h1>

            <h2 className="text-BCP-gray  md:text-[16px] md:leading-[24-px]">
              Puedes preguntarme sobre cualquier{" "}
              <span className="font-bold">producto o duda</span> del BCP. Aquí
              tienes unos ejemplos:
            </h2>
          </div>

          <div className="max-w-[680px] mx-auto">
            <SearchBar queryDisplayMode="searchPage" isInternalSearch={false} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchPage;
