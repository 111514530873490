export const retry = async (
  functionWithTheMainCode: () => Promise<void>,
  functionToRunWhenMaxRetries: () => void,
  maxAttempts: number = 5,
  initialDelay: number = 250,
) => {
  let attempt = 0;

  while (attempt < maxAttempts) {
    try {
      await functionWithTheMainCode();
      return;
    } catch (error) {
      attempt++;
      console.error(`Attempt ${attempt} failed: ${error}`);

      if (attempt >= maxAttempts) {
        console.error("Max retries reached.");
        functionToRunWhenMaxRetries();
        return;
      }

      const delay = Math.pow(2, attempt - 1) * initialDelay;
      await new Promise((resolve) => setTimeout(resolve, delay));
    }
  }
};
