import { Product } from "../context/SearchContext";
import {
  AnalyticsPayload,
  submitAnalytics,
} from "../services/AnalyticsService";

export const sendContextConfirmation = (
  products: Product[],
  origin: string,
  contextId: string,
) => {
  parent.postMessage(
    {
      action: "context:confirm",
      products,
      origin,
    },
    "*",
  );

  const payload: AnalyticsPayload = {
    action: "context:confirm",
    contextId,
    description: "Recibida respuesta de personalizar búsqueda",
    datetime: new Date().toISOString(),
    products,
  };
  submitAnalytics(payload);
};

export const sendContextRequest = (contextId: string) => {
  parent.postMessage(
    {
      action: "context:request",
    },
    "*",
  );

  const payload: AnalyticsPayload = {
    action: "context:request",
    contextId,
    description: "Click en banner de personalizar búsqueda",
    datetime: new Date().toISOString(),
  };

  submitAnalytics(payload);
};
