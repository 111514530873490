import React, { useEffect, useRef } from "react";
import { SearchProvider, useSearch } from "../context/SearchContext";
import SearchPage from "./SearchPage";
import ResponsePage from "./ResponsePage";

const MainPage: React.FC = () => {
  return (
    <SearchProvider>
      <div className="flex font-flexo flex-col min-h-screen justify-between w-full">
        <MainContent />
      </div>
    </SearchProvider>
  );
};

const MainContent: React.FC = () => {
  const { hasSearched, isLoading, setWasSeen } = useSearch();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setWasSeen(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 },
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [setWasSeen]);

  return (
    <div ref={containerRef}>
      {hasSearched || isLoading ? <ResponsePage /> : <SearchPage />}
    </div>
  );
};

export default MainPage;
