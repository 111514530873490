import { FC } from "react";

interface HappyFaceIconProps {
  className?: string;
  selected?: boolean;
}

export const HappyFaceIcon: FC<HappyFaceIconProps> = ({
  className,
  selected,
}) => {
  return (
    <div className={`icon-container cursor-pointer`}>
      <svg
        width="49"
        height="48"
        viewBox="0 0 49 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`transition fill duration-300 ${className}`}
      >
        <circle
          cx="24.5"
          cy="24"
          r="24"
          fill={selected ? "#F4FFEA" : "#FFFFFF"}
          className="transition fill duration-500"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.5 9.00061C32.7843 9.00061 39.5 15.7163 39.5 24.0006C39.5 32.2849 32.7843 39.0006 24.5 39.0006C16.2157 39.0006 9.5 32.2849 9.5 24.0006C9.5 15.7163 16.2157 9.00061 24.5 9.00061ZM32 24.0006C31.1716 24.0006 30.5 24.6722 30.5 25.5006C30.5 25.9784 29.9669 27.1299 29.1196 28.0424C27.9733 29.2769 26.4306 30.0006 24.5 30.0006C22.5694 30.0006 21.0267 29.2769 19.8804 28.0424C19.0983 27.2001 18.5839 26.1542 18.5094 25.6224L18.5 25.5006C18.5 24.6722 17.8284 24.0006 17 24.0006C16.1716 24.0006 15.5 24.6722 15.5 25.5006C15.5 28.8437 19.36 33.0006 24.5 33.0006C29.64 33.0006 33.5 28.8437 33.5 25.5006C33.5 24.6722 32.8284 24.0006 32 24.0006ZM29 17.2506C27.7574 17.2506 26.75 18.258 26.75 19.5006C26.75 20.7433 27.7574 21.7506 29 21.7506C30.2426 21.7506 31.25 20.7433 31.25 19.5006C31.25 18.258 30.2426 17.2506 29 17.2506ZM20 17.2506C18.7574 17.2506 17.75 18.258 17.75 19.5006C17.75 20.7433 18.7574 21.7506 20 21.7506C21.2426 21.7506 22.25 20.7433 22.25 19.5006C22.25 18.258 21.2426 17.2506 20 17.2506Z"
          fill={selected ? "#37A500" : "#606C7F"}
          className="transition fill duration-300"
        />
      </svg>
    </div>
  );
};
