import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from "react";
import { Source, PipelineService } from "../services/PipelineService";
import {
  sendInitialSearchClick,
  sendInternalSearchClick,
  sendSearchOpened,
  sendSearchRefresh,
  sendSearchResponse,
  sendSuggestionClick,
} from "../utils/analyticsEvents";
type SearchType = "initial" | "internal" | "suggestion" | "refresh";
import { v4 as uuidv4 } from "uuid";
import { retry } from "../utils/retryFunction";
import productDescriptions from "../assets/productsDescriptions.json";
import { sendContextConfirmation } from "../utils/contextEvents";

export interface Product {
  code: string;
  description: string;
}

interface ReceivedMessage {
  action: string;
  products: string[];
  origin: string;
}

interface SearchContextType {
  textResponse: string;
  hasSearched: boolean;
  isLoading: boolean;
  currentSearchTerm: string;
  sources: Source[];
  duration: number;
  requestId: string;
  contextId: string;
  handleSearch: (
    searchTerm: string,
    searchType: SearchType,
    maxRetries?: number,
  ) => Promise<void>;
  products: Product[];
  origin: string;
  contextReceived: boolean;
  suggestedQueries: string[];
  setSuggestedQueries: React.Dispatch<React.SetStateAction<string[]>>;
  setWasSeen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchContext = createContext<SearchContextType | undefined>(undefined);

// eslint-disable-next-line react-refresh/only-export-components
export const useSearch = () => {
  const context = useContext(SearchContext);
  if (!context) {
    throw new Error("useSearch must be used within a SearchProvider");
  }
  return context;
};

interface SearchProviderProps {
  children: ReactNode;
}

export const SearchProvider: React.FC<SearchProviderProps> = ({ children }) => {
  const [textResponse, setTextResponse] = useState<string>("");
  const [hasSearched, setHasSearched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentSearchTerm, setCurrentSearchTerm] = useState<string>("");
  const [sources, setSources] = useState<Source[]>([]);
  const [duration, setDuration] = useState(0);
  const [requestId, setRequestId] = useState<string>("");
  const [contextId, setContextId] = useState<string>("");
  const [wasSeen, setWasSeen] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [origin, setOrigin] = useState<string>("");
  const [contextReceived, setContextReceived] = useState(false);
  const [suggestedQueries, setSuggestedQueries] = useState<string[]>([]);

  useEffect(() => {
    const id = uuidv4();
    setContextId(id);
  }, []);

  useEffect(() => {
    if (wasSeen && contextId) {
      sendSearchOpened(contextId);
    }
  }, [wasSeen, contextId]);

  useEffect(() => {
    const handlePostMessage = (event: MessageEvent) => {
      if (event.data && typeof event.data === "object") {
        const data: ReceivedMessage = event.data;

        if (data.action === "context:response") {
          setContextReceived(true);
          if (Array.isArray(data.products)) {
            const mappedProducts = data.products.map((product) => ({
              code: product,
              description:
                productDescriptions[
                  product as keyof typeof productDescriptions
                ] || "Descripción no disponible",
            }));

            setProducts(mappedProducts);
            sendContextConfirmation(mappedProducts, data.origin, contextId);
          }

          setOrigin(data.origin);
        }
      }
    };

    window.addEventListener("message", handlePostMessage);
    return () => {
      window.removeEventListener("message", handlePostMessage);
    };
  }, [contextId]);

  const handleSearch = async (
    searchTerm: string,
    searchType: SearchType,
    maxRetries: number = 3,
  ) => {
    if (!searchTerm.length && searchType !== "refresh") return;
    let finalAgentId = "";
    const startTime = Date.now();

    setTextResponse("");
    setIsLoading(true);
    setSources([]);
    setRequestId("");
    setHasSearched(true);

    if (searchType !== "refresh") setCurrentSearchTerm(searchTerm);

    const sendPostMessage = (requestId: string) => {
      switch (searchType) {
        case "refresh":
          sendSearchRefresh(currentSearchTerm, requestId, contextId);
          break;
        case "internal":
          sendInternalSearchClick(
            searchTerm,
            requestId,
            contextId,
            suggestedQueries,
            finalAgentId,
          );
          break;
        case "initial":
          sendInitialSearchClick(
            searchTerm,
            requestId,
            contextId,
            suggestedQueries,
            finalAgentId,
          );
          break;
        case "suggestion":
          sendSuggestionClick(
            searchTerm,
            requestId,
            contextId,
            suggestedQueries,
            finalAgentId,
          );
          break;
        default:
          break;
      }
    };
    const metadata = JSON.stringify({ products, origin });
    let finalTextResponse = "";
    let finalSources: Source[] = [];
    let finalRequestId = "";
    const mainSearchFunction = async () => {
      await PipelineService.ragStream(
        searchType === "refresh" ? currentSearchTerm : searchTerm,
        (text) => {
          finalTextResponse = text;
          setTextResponse(text);
        },
        (sources) => {
          finalSources = sources;
          setSources(sources);
        },
        (requestId) => {
          finalRequestId = requestId;
          setRequestId(requestId);

          if (requestId !== "") {
            sendPostMessage(requestId);
          }
        },
        (agentId) => {
          finalAgentId = agentId;
        },
        contextId,
        metadata,
      );
    };

    const onMaxRetriesReached = () => {
      finalRequestId = "-1";
      setRequestId("-1");
      setHasSearched(false);
    };

    await retry(mainSearchFunction, onMaxRetriesReached, maxRetries, 250);

    const endTime = Date.now();
    const finalDuration = endTime - startTime;
    setDuration(finalDuration);
    setIsLoading(false);

    if (finalRequestId !== "-1") {
      sendSearchResponse(
        searchType === "refresh" ? currentSearchTerm : searchTerm,
        finalRequestId,
        finalTextResponse,
        finalSources,
        finalDuration,
        contextId,
        products,
        finalAgentId,
      );
    }
  };

  const value: SearchContextType = {
    textResponse,
    hasSearched,
    isLoading,
    currentSearchTerm,
    sources,
    duration,
    requestId,
    contextId,
    handleSearch,
    products,
    origin,
    contextReceived,
    suggestedQueries,
    setSuggestedQueries,
    setWasSeen,
  };

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
};

export { SearchContext };
