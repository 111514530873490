import { FC } from "react";

interface AngryFaceIconProps {
  className?: string;
  selected?: boolean;
}

export const AngryFaceIcon: FC<AngryFaceIconProps> = ({
  className,
  selected,
}) => {
  return (
    <div className={`icon-container cursor-pointer`}>
      <svg
        width="49"
        height="48"
        viewBox="0 0 49 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`transition fill duration-300 ${className}`}
      >
        <circle
          cx="24.5"
          cy="24"
          r="24"
          fill={selected ? "#FFF2F5" : "#FFFFFF"}
          className="transition fill duration-500"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.5 8.25C33.1985 8.25 40.25 15.3015 40.25 24C40.25 32.6985 33.1985 39.75 24.5 39.75C15.8015 39.75 8.75 32.6985 8.75 24C8.75 15.3015 15.8015 8.25 24.5 8.25ZM24.5 25.5749C21.3793 25.5749 18.8124 26.8583 16.94 29.3549C16.4181 30.0508 16.5591 31.038 17.255 31.5599C17.9509 32.0818 18.9381 31.9408 19.46 31.2449C20.7376 29.5415 22.3707 28.7249 24.5 28.7249C26.6293 28.7249 28.2624 29.5415 29.54 31.2449C30.0619 31.9408 31.0491 32.0818 31.745 31.5599C32.4409 31.038 32.5819 30.0508 32.06 29.3549C30.1876 26.8583 27.6207 25.5749 24.5 25.5749ZM33.8695 17.2045C34.1442 18.0298 33.6979 18.9216 32.8726 19.1963L31.2996 19.7189C31.4832 20.0548 31.5875 20.4402 31.5875 20.85C31.5875 22.1548 30.5298 23.2125 29.225 23.2125C27.9202 23.2125 26.8625 22.1548 26.8625 20.85C26.8625 20.7796 26.8656 20.7099 26.8716 20.6411C26.5449 20.4586 26.2834 20.1566 26.1555 19.7725C25.8808 18.9472 26.3271 18.0554 27.1524 17.7806L31.8776 16.2076C32.7029 15.9328 33.5947 16.3791 33.8695 17.2045ZM17.078 16.2037L21.8522 17.7768C22.6665 18.0451 23.1125 18.9334 22.8485 19.7608C22.723 20.1542 22.4595 20.463 22.1302 20.6499L22.1375 20.85C22.1375 22.1548 21.0798 23.2125 19.775 23.2125C18.4702 23.2125 17.4125 22.1548 17.4125 20.85C17.4125 20.4409 17.5165 20.056 17.6995 19.7205L16.1218 19.2001C15.3075 18.9318 14.8614 18.0436 15.1255 17.2161C15.3895 16.3887 16.2637 15.9354 17.078 16.2037Z"
          fill={selected ? "#E30425" : "#606C7F"}
          className="transition fill duration-300"
        />
      </svg>
    </div>
  );
};
