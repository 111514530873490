import { FC } from "react";

interface SearchIconButtonProps {
  className?: string;
  disabled?: boolean;
}

export const SearchIconButton: FC<SearchIconButtonProps> = ({
  className,
  disabled,
}) => {
  return (
    <div className={`icon-container ${disabled ? "fade-out" : "fade-in"}`}>
      {disabled ? (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="transition-opacity duration-500 opacity-50 ${className}"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 10.0107C20.0005 9.62127 19.775 9.26352 19.4138 9.08068L1.68411 0.124704C1.2679 -0.087508 0.755934 -0.0246663 0.409767 0.281126C0.050847 0.557919 -0.0895156 1.01785 0.0577897 1.43445L3.05859 10.0353L0.0552385 18.6369C-0.0597863 19.0106 0.0514282 19.4142 0.344344 19.686L0.406878 19.7455C0.750717 20.027 1.24044 20.0801 1.64209 19.8792L19.4135 10.9408C19.7748 10.7579 20.0004 10.4001 20 10.0107Z"
            fill="#BFC4CC"
          />
        </svg>
      ) : (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={`transition-opacity duration-500 opacity-100 ${className}`}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 10.0107C20.0005 9.62127 19.775 9.26352 19.4138 9.08068L1.68411 0.124704C1.2679 -0.087508 0.755934 -0.0246663 0.409767 0.281126C0.050847 0.557919 -0.0895156 1.01785 0.0577897 1.43445L3.05859 10.0353L0.0552385 18.6369C-0.0597863 19.0106 0.0514282 19.4142 0.344344 19.686L0.406878 19.7455C0.750717 20.027 1.24044 20.0801 1.64209 19.8792L19.4135 10.9408C19.7748 10.7579 20.0004 10.4001 20 10.0107Z"
            fill="url(#paint0_linear_58_630)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_58_630"
              x1="10"
              y1="0"
              x2="10"
              y2="20"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.065" stopColor="#124DE3" />
              <stop offset="0.65" stopColor="#D23232" />
            </linearGradient>
          </defs>
        </svg>
      )}
    </div>
  );
};

export default SearchIconButton;
