export const getBrowserInfo = (): BrowserInfo => {
  return {
    userAgent: navigator.userAgent,
    platform: navigator.platform,
    language: navigator.language,
    appName: navigator.appName,
  };
};

export interface BrowserInfo {
  userAgent: string;
  platform: string;
  language: string;
  appName: string;
}
