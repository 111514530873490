import React, { useEffect } from "react";
import recommendations from "../../assets/recommendations.json";
import RecommendationButton from "../atoms/RecommendationButton";
import { useSearch } from "../../context/SearchContext";
import { logException } from "../../utils/logger";

const DEFAULT_RECOMMENDATIONS = {
  n: 3,
  randomSelectionEnabled: false,
  labels: [
    "¿Cómo abrir mi primera cuenta de ahorro en BCP?",
    "¿Cómo cambiar soles y dólares?",
    "¿Qué es Warda?",
  ],
};

interface SuggestedQueriesProps {
  onQueryClick: (query: string) => void;
}

const SuggestedQueries: React.FC<SuggestedQueriesProps> = ({
  onQueryClick,
}) => {
  const { suggestedQueries, setSuggestedQueries } = useSearch();

  const processAndSetSuggestions = (data: {
    n: number;
    labels: string[];
    randomSelectionEnabled: boolean;
  }) => {
    const { n, labels, randomSelectionEnabled } = data;

    if (!n || !Array.isArray(labels)) {
      const errorMessage =
        "Invalid data structure in JSON with suggested queries.";
      logException(errorMessage);
      throw new Error(errorMessage);
    }

    let selectedLabels = labels;

    if (randomSelectionEnabled) {
      const shuffledLabels = [...labels].sort(() => 0.5 - Math.random());
      selectedLabels = shuffledLabels.slice(0, n);
    } else {
      selectedLabels = labels.slice(0, n);
    }

    setSuggestedQueries(selectedLabels);
  };

  useEffect(() => {
    try {
      processAndSetSuggestions(recommendations);
    } catch (error) {
      console.error("Error loading recommendations from JSON:", error);
      processAndSetSuggestions(DEFAULT_RECOMMENDATIONS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="space-y-4">
      <div className="flex flex-wrap gap-3 max-w-[600px]">
        {suggestedQueries.map((label) => (
          <RecommendationButton
            key={label}
            label={label}
            onClick={() => onQueryClick(label)}
          />
        ))}
      </div>
    </div>
  );
};

export default SuggestedQueries;
