import { logException } from "../utils/logger";

export enum FeedbackOption {
  Perfect = "Perfecto",
  AlmostGood = "Casi bien",
  NeedsWork = "Necesita mejorar",
  Bad = "Mal",
}

const submitFeedbackEllmental = async (
  userFeedback: string,
  userMetadata: object,
  requestId: string,
) => {
  const url = `${import.meta.env.VITE_SEARCH_SERVICE_URL}/feedback`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${import.meta.env.VITE_SEARCH_SERVICE_KEY}`,
      },
      body: JSON.stringify({
        user_feedback: userFeedback,
        user_metadata: userMetadata,
        request_id: requestId,
      }),
    });

    if (!response.ok) {
      throw new Error(`Failed to send feedback: ${response.statusText}`);
    }
  } catch (error) {
    logException(error);
  }
};

export { submitFeedbackEllmental };
