import React, { useRef, useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import SearchIcon from "../../assets/SearchIcon";
import SearchIconButton from "../../assets/SearchIconButton";
import SuggestedQueries from "./SuggestedQueries";
import { PoweredByAIFindr } from "../../assets/PoweredByAIFindr";
import { useSearch } from "../../context/SearchContext";
import { UserPhoneImg } from "../../assets/UserPhoneImg";
import RoundedButton from "../atoms/RoundedButton";
import { sendContextRequest } from "../../utils/contextEvents";
import CustomAlert from "../atoms/CustomAlert";

interface SearchBarProps {
  queryDisplayMode: "hideSuggestedQueries" | "responsePage" | "searchPage";
  isInternalSearch: boolean;
  isLoading?: boolean;
}

const SearchBar: React.FC<SearchBarProps> = ({
  queryDisplayMode,
  isInternalSearch,
  isLoading = false,
}) => {
  const { handleSearch, contextReceived, contextId } = useSearch();
  const [search, setSearch] = useState("");
  const [isActive, setIsActive] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const suggestedQueriesRef = useRef<HTMLDivElement>(null);
  const disabled = search === "" || isLoading;
  const placeholderText = isInternalSearch
    ? "¿En qué más te puedo ayudar?"
    : "¿En qué te puedo ayudar?";
  const [contextRequestSent, setContextRequestSent] = useState(false);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const searchType = isInternalSearch ? "internal" : "initial";
    handleSearch(search, searchType);
    setSearch("");
    setIsActive(false);
  };

  const handleQueryClick = (query: string) => {
    handleSearch(query, "suggestion");
    setSearch("");
    setIsActive(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        queryDisplayMode === "responsePage" &&
        inputRef.current &&
        !inputRef.current.contains(event.target as Node) &&
        suggestedQueriesRef.current &&
        !suggestedQueriesRef.current.contains(event.target as Node)
      ) {
        setIsActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [queryDisplayMode]);

  const queries = <SuggestedQueries onQueryClick={handleQueryClick} />;

  const requestContext = () => {
    sendContextRequest(contextId);
    setContextRequestSent(true);
  };

  const contextBanner = (
    <div className="bg-BCP-light-blue flex space-x-8 justify-center items-center max-w-[800px] mx-auto md:px-8  rounded-[16px]">
      <UserPhoneImg />
      <div className="space-y-2 md:space-x-8 md:items-center flex flex-col md:flex-row py-4">
        <div>
          <h3 className="font-bold text-[16px]">Personaliza tus búsquedas</h3>
          <h4 className="text-[12px] md:text-[14px]">
            Y te daré más detalle sobre tus productos preaprobados
          </h4>
        </div>
        <span className="md:w-[130px] w-[100px] ">
          <RoundedButton
            onClick={() => requestContext()}
            label="Probar ahora"
            customClass="text-[12px] md:text-[14px] h-[32px] md:h-[30px]"
          />
        </span>
      </div>
    </div>
  );

  const contextConfirmAlert = (
    <div className="max-w-[330px]">
      <CustomAlert message="¡Todo listo! Empieza tu búsqueda personalizada." />
    </div>
  );

  const renderSearchPageComponents = () => {
    if (queryDisplayMode !== "searchPage") return null;

    return (
      <>
        {queries}
        {!contextReceived && contextBanner}
        {contextReceived && contextRequestSent && contextConfirmAlert}
      </>
    );
  };

  const renderSuggestedQueries = () => {
    if (queryDisplayMode === "hideSuggestedQueries") {
      return null;
    }

    if (queryDisplayMode === "responsePage") {
      return (
        <div
          ref={suggestedQueriesRef}
          className={`
            w-full 
            transition-all duration-300 ease-in-out
            ${
              isActive
                ? "opacity-100 max-h-96 overflow-y-auto"
                : "opacity-0 max-h-0 overflow-hidden"
            }
          `}
        >
          {queries}
        </div>
      );
    }

    return <></>;
  };

  return (
    <div
      className={`
        relative space-y-2 transition-all duration-300 ease-in-out
        ${
          isActive && queryDisplayMode === "responsePage"
            ? "-translate-y-0"
            : ""
        }
      `}
      ref={wrapperRef}
    >
      <div className="flex justify-center items-center w-full">
        <div className=" justify-center items-center w-full space-y-4">
          {renderSearchPageComponents()}

          <form onSubmit={handleSubmit} className="w-full">
            <div className="flex space-x-2">
              <span className="flex-grow w-full relative">
                <div className="w-full p-[3px] shadow-lg rounded-full bg-gradient-to-r from-BCP-gradient-blue to-BCP-gradient-red">
                  <InputText
                    ref={inputRef}
                    id="in"
                    placeholder={placeholderText}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onFocus={() => setIsActive(true)}
                    className="w-full h-12 font-flexo rounded-full pl-10 pr-10 p-2.5 text-BCP-gray text-md bg-white"
                  />
                </div>
                <span className="absolute inset-y-0 pt-1 -left-1 flex items-center pl-4 pb-1">
                  <SearchIcon />
                </span>
                <span className="absolute inset-y-0 pt-0.5 end-4 flex items-center pl-4 pb-1">
                  <button disabled={disabled}>
                    <SearchIconButton disabled={disabled} />
                  </button>
                </span>
              </span>
            </div>
          </form>
        </div>
      </div>
      <div className="flex justify-center pt-1">
        <PoweredByAIFindr width="124" height="14" />
      </div>

      {renderSuggestedQueries()}
    </div>
  );
};

export default SearchBar;
