import { FC } from "react";

interface NeutralFaceIconProps {
  className?: string;
  selected?: boolean;
}

export const NeutralFaceIcon: FC<NeutralFaceIconProps> = ({
  className,
  selected,
}) => {
  return (
    <div className={`icon-container cursor-pointer`}>
      <svg
        width="49"
        height="48"
        viewBox="0 0 49 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`transition fill duration-300 ${className}`}
      >
        <circle
          cx="24.5"
          cy="24"
          r="24"
          fill={selected ? "#FFFDED" : "#FFFFFF"}
          className="transition fill duration-500"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.5 9.00061C32.7843 9.00061 39.5 15.7163 39.5 24.0006C39.5 32.2849 32.7843 39.0006 24.5 39.0006C16.2157 39.0006 9.5 32.2849 9.5 24.0006C9.5 15.7163 16.2157 9.00061 24.5 9.00061ZM30.5 25.5006H18.5C17.6716 25.5006 17 26.1722 17 27.0006C17 27.7699 17.5791 28.4039 18.3251 28.4905L18.5 28.5006H30.5C31.3284 28.5006 32 27.829 32 27.0006C32 26.1722 31.3284 25.5006 30.5 25.5006ZM29 17.2506C27.7574 17.2506 26.75 18.258 26.75 19.5006C26.75 20.7433 27.7574 21.7506 29 21.7506C30.2426 21.7506 31.25 20.7433 31.25 19.5006C31.25 18.258 30.2426 17.2506 29 17.2506ZM20 17.2506C18.7574 17.2506 17.75 18.258 17.75 19.5006C17.75 20.7433 18.7574 21.7506 20 21.7506C21.2426 21.7506 22.25 20.7433 22.25 19.5006C22.25 18.258 21.2426 17.2506 20 17.2506Z"
          fill={selected ? "#FFCB1F" : "#606C7F"}
          className="transition fill duration-300"
        />
      </svg>
    </div>
  );
};
