interface RecommendationButtonProps {
  label: string;
  onClick?: () => void;
}
export function RecommendationButton({
  label,
  onClick,
}: RecommendationButtonProps) {
  return (
    <button
      style={{ boxShadow: "none" }}
      className={` text-BCP-dark-blue py-1.5 px-3 truncate font-bold text-[14px] border border-BCP-light-gray-hover rounded-full transition-colors duration-300 hover:bg-BCP-light-gray-hover `}
      onClick={onClick}
    >
      {label}
    </button>
  );
}

export default RecommendationButton;
