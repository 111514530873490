import { FC } from "react";

export const UserPhoneImg: FC = () => {
  return (
    <svg
      width="106"
      height="110"
      viewBox="0 0 106 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_4142)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M48.3464 59.5258C49.2249 59.4723 50.6677 57.0465 50.6677 57.0465C50.6677 57.0465 58.0002 58.9139 61.166 62.8661C64.5394 67.0781 69.8595 82.479 81.818 78.0939L89.9292 78.1007L94.075 84.4376C94.075 84.4376 84.7457 96.6398 68.1371 89.5164C53.181 83.1019 49.13 64.7959 49.13 64.7959L48.3464 59.5258Z"
          fill="#27B7FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M56.4791 74.1184C56.4791 74.1184 55.3955 75.5486 54.2896 77.0147C57.2328 81.7969 61.6615 86.7995 68.1338 89.5753C72.3505 91.3838 76.0931 91.8868 79.3379 91.7201C63.2315 88.3377 56.4791 74.1184 56.4791 74.1184Z"
          fill="#06A0FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M70.667 113.585C70.8819 113.167 71.22 112.84 71.5 112.5C69.2617 110.606 62.5113 96.9115 58.4699 86.2119C55.2285 77.6296 58.6799 65.6191 51.065 57.1641C51.065 57.1641 44.7256 55.3623 41.189 53.2508L30.4771 56.2305L28.4372 56.1886C25.5713 60.2964 19.7688 61.8501 17.0998 64.3578C17.0998 64.3578 21.273 85.9371 24.2708 101.596L26.6764 104.657C38.5 122.5 64.4492 125.682 70.667 113.585Z"
          fill="#27B7FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.2097 46.6799L29.3185 46.1986C29.3185 46.1986 28.8611 43.1797 28.0489 41.4197C27.2367 39.6592 24.4975 35.8376 25.8164 31.1039C27.1357 26.3702 30.4543 23.3078 35.7159 22.4867C41.538 21.578 46.8397 24.7911 47.2764 28.022C47.2764 28.022 49.0297 28.8124 49.1704 31.1305C49.2692 32.7586 48.0501 33.9293 48.0501 33.9293L36.7516 33.0967L30.2097 46.6799Z"
          fill="#FD5C00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.2838 56.3975C30.0612 53.2424 29.3196 46.1982 29.3196 46.1982C31.106 44.0025 30.4387 40.9134 30.4387 40.9134C30.4387 40.9134 29.1723 38.9317 29.1055 38.7972C29.0383 38.6627 28.817 38.2422 29.2762 37.9004C29.7357 37.5583 30.1411 37.9493 30.1411 37.9493C30.1411 37.9493 31.2543 39.3404 31.5509 39.7101C31.8476 40.0797 32.0798 39.7502 32.0798 39.7502C34.1626 37.8704 33.5998 34.9141 33.2343 33.5508C32.9408 32.4567 32.0328 30.5402 33.8895 29.1713C37.1605 26.7593 41.1068 32.5355 44.6962 32.2798C46.2975 32.1657 46.7115 31.2706 46.7115 31.2706C49.4958 34.5355 48.3984 41.2522 48.2873 46.9074C48.2396 49.3479 48.3032 51.0425 46.7305 52.2465C45.1078 53.4887 43.0253 53.1741 43.0253 53.1741C43.0253 53.1741 43.224 56.4487 44.1813 60.6004C44.1813 60.6004 43.5135 61.8599 40.4138 61.7903C31.9794 60.9748 28.2838 56.3975 28.2838 56.3975Z"
          fill="#FFC8E9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.5649 34.8957C38.6579 34.1187 40.0508 33.7854 41.7016 34.168C42.0261 34.2432 42.2226 34.5526 42.1404 34.8592C42.0583 35.1657 41.7286 35.3533 41.4041 35.2781C40.1285 34.9825 39.1046 35.2275 38.29 35.8065C37.9854 36.023 37.7873 36.2283 37.7015 36.3452C37.5103 36.6056 37.1326 36.6721 36.8578 36.4938C36.5831 36.3154 36.5154 35.9597 36.7066 35.6993C36.8618 35.488 37.1459 35.1936 37.5649 34.8957Z"
          fill="#002199"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45.6886 34.1215L45.8246 34.1199L46.0035 34.1234L46.2204 34.1339C46.6104 34.1581 47.0032 34.2117 47.3787 34.3043C48.0002 34.4577 48.5169 34.703 48.8862 35.0757C49.1139 35.3054 49.0995 35.6671 48.8542 35.8835C48.6293 36.0819 48.2883 36.0893 48.0577 35.9122L47.9977 35.8593C47.8074 35.6674 47.4846 35.5141 47.0637 35.4103C46.77 35.3378 46.4508 35.2942 46.1319 35.2745L45.9262 35.2649L45.7207 35.2638C45.3861 35.2745 45.1076 35.0275 45.0988 34.712C45.0899 34.3965 45.354 34.1321 45.6886 34.1215Z"
          fill="#002199"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.9479 36.4581C44.0466 36.4378 44.1435 36.4968 44.1642 36.5898L44.222 36.822L44.304 37.1175L44.4025 37.4407C44.6059 38.0798 44.8572 38.7158 45.1585 39.3039C45.3684 39.7137 45.5956 40.0855 45.8399 40.4108L45.946 40.5447L46.0918 40.7216L46.164 40.8159C46.2522 40.9377 46.3058 41.044 46.3259 41.1567C46.3525 41.3062 46.3145 41.4521 46.2099 41.5931C45.9119 41.9952 44.984 42.0059 44.5025 41.5897C44.4284 41.5256 44.4239 41.4166 44.4924 41.3462C44.5609 41.2759 44.6765 41.2708 44.7506 41.3348C45.0811 41.6205 45.751 41.6128 45.9109 41.3971C45.9615 41.3288 45.9757 41.2742 45.9654 41.2162L45.9535 41.1606L45.9389 41.1253L45.9119 41.0811L45.835 40.9786L45.5411 40.6113C45.2844 40.2695 45.0474 39.8816 44.8292 39.4557C44.5187 38.8496 44.2609 38.1972 44.0524 37.542L43.9515 37.2107L43.8502 36.842L43.8065 36.6631C43.7858 36.5701 43.8491 36.4783 43.9479 36.4581Z"
          fill="#002199"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M47.6025 37.355C47.6171 37.5955 47.4221 37.8031 47.1669 37.8187C46.9121 37.8342 46.6933 37.6518 46.6788 37.4113C46.6642 37.1711 46.8592 36.9635 47.1141 36.9479C47.3692 36.9324 47.588 37.1148 47.6025 37.355Z"
          fill="#002199"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.3897 37.2549C40.4053 37.5115 40.1972 37.733 39.925 37.7495C39.6527 37.7661 39.4197 37.5716 39.4042 37.315C39.3886 37.0583 39.5963 36.8369 39.8686 36.8203C40.1408 36.8037 40.3741 36.9983 40.3897 37.2549Z"
          fill="#002199"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.736 42.7313C40.8807 42.6509 41.0662 42.6959 41.1504 42.8318L41.1741 42.8682L41.2482 42.9712C41.2788 43.0116 41.3153 43.0579 41.3576 43.109C41.5365 43.3247 41.7507 43.5387 41.9991 43.735C42.6159 44.2224 43.3348 44.519 44.1569 44.543C44.3241 44.5479 44.4545 44.6798 44.4481 44.8376C44.4417 44.9954 44.3008 45.1193 44.1336 45.1144C43.1647 45.086 42.3212 44.7381 41.6077 44.1743C41.3252 43.951 41.0825 43.7085 40.879 43.4632L40.8097 43.3776L40.6992 43.2312C40.6686 43.1884 40.6443 43.1519 40.6263 43.1228C40.5422 42.9869 40.5913 42.8116 40.736 42.7313Z"
          fill="#002199"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.2694 101.596L26.6751 104.657C31.3749 112.851 42.7954 118.995 53.2553 114.809C31.7795 114.016 27.0935 82.4967 27.3741 74.3125L24.0703 77.2276L20.1535 80.1831C21.4571 86.9455 22.9731 94.8236 24.2694 101.596Z"
          fill="#06A0FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.6134 66.496C22.28 62.0404 17.0985 64.3554 17.0985 64.3554C17.0985 64.3554 9.93141 68.9288 9.01515 78.365C8.09893 87.8018 11.4098 95.8725 15.7511 100.261C20.0925 104.65 33.0507 110.286 52.1008 103.955C60.0073 101.328 66.0911 95.5007 70.7075 89.9154L67.648 83.8035L62.5551 81.2335C62.5551 81.2335 41.6706 98.7136 29.0633 89.774C25.7061 87.3934 23.6961 83.9705 26.4297 77.5791C26.4297 77.5791 29.3659 71.5116 25.6134 66.496Z"
          fill="#27B7FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.3398 52.3774C29.3398 52.3774 28.8515 55.3903 28.2841 56.3979C28.2841 56.3979 31.9793 60.9752 40.4138 61.791C43.5134 61.8606 44.1816 60.6011 44.1816 60.6011C43.8703 59.2512 43.6398 57.9949 43.4696 56.9126C36.9572 58.8255 29.3398 52.3774 29.3398 52.3774Z"
          fill="#27B7FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.3397 56.4679C28.4158 56.4049 28.5313 56.4118 28.5975 56.4832C29.3567 57.302 30.5078 58.236 32.072 59.1112C34.395 60.4108 37.1747 61.3049 40.42 61.6192C41.8497 61.6513 42.8533 61.406 43.5084 60.9919C43.7328 60.85 43.8769 60.7165 43.9579 60.6153L43.988 60.5732L43.8469 59.9387C43.6958 59.2384 43.5603 58.5404 43.4396 57.8482L43.3237 57.1581C43.3086 57.0641 43.3772 56.9758 43.477 56.9608C43.5767 56.9458 43.6699 57.0098 43.6851 57.1038C43.8322 58.0156 44.0048 58.9395 44.205 59.8675L44.3604 60.5641L44.3451 60.6781C44.2631 60.8327 44.065 61.0535 43.7104 61.2777C42.9886 61.734 41.9105 61.9975 40.3943 61.9629C37.081 61.6426 34.251 60.7323 31.8838 59.4079C30.2848 58.5133 29.1048 57.5559 28.3215 56.7111C28.2553 56.6397 28.2635 56.5308 28.3397 56.4679Z"
          fill="#06A0FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.736 49.2896C35.736 49.2896 35.0247 49.0285 35.269 50.1491C35.5134 51.2698 37.6484 55.4606 40.9092 55.9267C41.8286 56.0581 42.5824 55.6132 43.1916 54.9075C43.0639 53.8239 43.0245 53.1757 43.0245 53.1757C43.0245 53.1757 39.5484 52.7137 35.736 49.2896Z"
          fill="#FF9FD1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.8743 88.7598C54.752 92.6836 57.3942 98.5501 58.1582 101.168C63.2098 98.1739 67.3485 93.9817 70.7081 89.9165L67.6489 83.8048L62.1524 81.2591C62.1524 81.2591 57.5981 85.4089 50.8743 88.7598Z"
          fill="#06A0FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M70.8579 89.9664L67.7983 83.8544L62.4429 80.9882C62.4429 80.9882 59.8687 83.3636 55.7342 86.0221C57.701 87.5924 61.2959 91.1459 64.1047 96.8146C66.7146 94.5672 68.8392 92.409 70.8579 89.9664Z"
          fill="#FFC8E9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M81.9514 78.0223C81.2929 78.2635 80.6555 78.4384 80.035 78.5595L79.2982 80.7897L83.1718 90.3882L84.8131 90.6573C90.9343 88.6476 94.2086 84.3661 94.2086 84.3661L90.0622 78.0292L81.9514 78.0223Z"
          fill="#FFC8E9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M98.9847 78.3405C99.6506 78.9352 99.6752 79.9299 99.0396 80.5622L98.6568 80.9359L98.2764 81.3004L97.8597 81.6934C97.7874 81.7611 97.7137 81.8299 97.6386 81.8997C96.5657 82.8968 95.4323 83.8978 94.2863 84.8402C93.5174 85.4725 92.7677 86.0576 92.0441 86.5855C91.5906 86.9163 91.1497 87.223 90.7223 87.5038C89.9644 88.0018 88.9265 87.8291 88.4041 87.1181C87.8816 86.4071 88.0725 85.4271 88.8303 84.9291C89.2097 84.6798 89.6058 84.4043 90.0165 84.1047C90.6846 83.6173 91.3842 83.0713 92.1059 82.4778C93.1955 81.5818 94.2803 80.6237 95.3077 79.6689L95.7229 79.2803L96.1036 78.9183L96.6283 78.4088C97.2639 77.7765 98.3189 77.7459 98.9847 78.3405Z"
          fill="#FFC8E9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M99.7925 75.2954C100.385 75.9553 100.295 76.9474 99.5908 77.5113L99.0023 77.9787L98.642 78.2627C98.1055 78.6845 97.5625 79.1068 97.0311 79.5142L96.21 80.1385L95.7953 80.4497L95.0421 81.006L94.7018 81.2517L94.3845 81.4761L94.0892 81.6798L93.815 81.8629L93.561 82.0258L93.3262 82.1688C93.2511 82.2132 93.179 82.2544 93.1098 82.2923L92.9108 82.3966C92.7519 82.4756 92.6103 82.535 92.4833 82.5755C91.6096 82.8548 90.6665 82.4148 90.3769 81.5929C90.0998 80.8068 90.521 79.9559 91.3228 79.6391L91.451 79.5923L91.5473 79.5373L91.7088 79.434L92.2025 79.0981L92.638 78.7912L93.2761 78.3304L93.9913 77.801L94.4263 77.4727C94.9354 77.0865 95.4633 76.6795 95.9913 76.2675L97.4441 75.1216C98.1483 74.5577 99.1997 74.6355 99.7925 75.2954Z"
          fill="#FFC8E9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M94.0409 83.0111L86.4612 79.4652C85.6138 79.0689 85.2727 78.0973 85.6994 77.2956L95.2765 59.2928C95.7035 58.491 96.736 58.1622 97.5834 58.5585L105.163 62.1044C106.011 62.5008 106.352 63.4723 105.925 64.2741L96.3478 82.2768C95.9209 83.0786 94.8884 83.4075 94.0409 83.0111Z"
          fill="#005CFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M105.58 62.9167L105.744 62.5529L105.031 61.6851L104.913 62.3715L105.58 62.9167Z"
          fill="#005CFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M93.2201 82L85.6401 78.4544C84.793 78.0577 84.4519 77.0865 84.8786 76.2844L94.4557 58.282C94.8823 57.4799 95.9152 57.1513 96.7626 57.5474L104.342 61.0936C105.19 61.49 105.531 62.4612 105.104 63.2633L95.5267 81.2657C95.1001 82.0678 94.0676 82.3963 93.2201 82Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M89.5576 72.9808C90.2341 72.3874 91.2885 72.4199 91.9128 73.0533C92.5371 73.6868 92.4949 74.6814 91.8185 75.2749C90.6853 76.2689 89.8 76.7141 88.1928 77.2134L87.7132 77.3581L87.1255 77.5296L86.8455 77.6161L86.608 77.6955C86.5714 77.7083 86.5363 77.7209 86.5023 77.7335L86.3116 77.8087C86.1921 77.8589 86.086 77.9102 85.9811 77.9672C85.538 78.2078 85.1171 78.5582 84.7308 78.9919C84.444 79.3139 84.1947 79.6605 83.9879 80.0034L83.8963 80.1595L83.8012 80.3343L83.7663 80.4055C83.4023 81.2052 82.4218 81.5801 81.5763 81.2427C80.7309 80.9053 80.3407 79.9834 80.7047 79.1837C80.9672 78.6071 81.4513 77.8043 82.1849 76.9807C82.8087 76.2804 83.5206 75.6877 84.3336 75.2461C84.7194 75.0366 85.0695 74.8849 85.5178 74.7313L85.8746 74.6147L86.2809 74.4919L86.9286 74.3026L87.3046 74.1865C88.3691 73.8466 88.8877 73.5686 89.5576 72.9808Z"
          fill="#FFC8E9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M86.4038 76.9665L88.5046 76.557L88.6236 77.0621C88.6236 77.0621 87.6127 77.5947 87.3135 78.7208C87.0147 79.8469 86.8951 82.9603 85.2237 83.5049C83.552 84.0501 82.9644 78.7226 82.9915 78.6794C83.0178 78.6361 86.4038 76.9665 86.4038 76.9665Z"
          fill="#FFC8E9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M73.225 76.6613C73.5397 77.4346 73.1597 78.3023 72.3737 78.653L71.9376 78.8272L71.7309 78.914C71.2761 79.1076 70.7671 79.3424 70.2185 79.6185C68.648 80.409 67.0875 81.3661 65.6533 82.4861C65.0499 82.9574 64.4809 83.4489 63.9515 83.9602C63.306 84.5835 62.2507 84.5992 61.5945 83.9951C60.9382 83.3911 60.9295 82.396 61.5749 81.7727C62.1897 81.179 62.8459 80.6121 63.5385 80.0713C65.161 78.8041 66.9077 77.7328 68.6659 76.8479L69.0782 76.6437L69.4654 76.4582L69.8257 76.2915L70.1577 76.1433L70.4596 76.0137L70.8528 75.8539L71.0727 75.7703C71.9324 75.454 72.896 75.853 73.225 76.6613Z"
          fill="#FFC8E9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M69.9957 81.4666C70.4918 82.1943 70.2653 83.1678 69.4899 83.641L69.3644 83.7203L69.0489 83.9287L68.6662 84.1935C68.5995 84.2408 68.5313 84.2895 68.4619 84.3398C67.6665 84.9156 66.8737 85.5623 66.143 86.2597C65.4936 86.8794 64.4383 86.8892 63.7859 86.2814C63.1335 85.6737 63.131 84.6786 63.7804 84.0588C64.6279 83.25 65.5345 82.5105 66.4443 81.8518L66.7599 81.6265L67.0454 81.4285L67.2979 81.2586L67.5149 81.1175L67.6935 81.0057C68.469 80.5326 69.4997 80.7389 69.9957 81.4666Z"
          fill="#FFC8E9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M71.774 83.898C72.2701 84.6257 72.0436 85.5992 71.2682 86.0724L71.0043 86.242C70.7751 86.393 70.518 86.5701 70.2403 86.7712C69.4449 87.347 68.6521 87.9938 67.9215 88.6913C67.2722 89.3111 66.2169 89.3209 65.5644 88.7132C64.9119 88.1055 64.9093 87.1104 65.5586 86.4906C66.4062 85.6816 67.3128 84.942 68.2225 84.2833L68.5382 84.0579L68.8237 83.8599L69.1894 83.6158L69.4719 83.4371C70.2473 82.964 71.278 83.1703 71.774 83.898Z"
          fill="#FFC8E9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M73.4322 85.9799C73.9726 86.6294 73.8794 87.5596 73.2385 88.1116L73.1084 88.2138L72.8575 88.4056L72.4818 88.6998L72.0695 89.031C71.2532 89.6947 70.4381 90.4034 69.6834 91.1237C69.0341 91.7434 67.9788 91.7531 67.3263 91.1454C66.6739 90.5376 66.6714 89.5425 67.3208 88.9228C68.1446 88.1366 69.0238 87.3721 69.9036 86.6568L70.3485 86.2995L70.8452 85.9116L71.0929 85.7238C71.8186 85.1848 72.866 85.2995 73.4322 85.9799Z"
          fill="#FFC8E9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.943 57.6076C41.029 57.5979 41.1077 57.646 41.1356 57.7194L41.1451 57.7581L41.5399 61.0599C41.5512 61.1544 41.4791 61.2402 41.3788 61.2515C41.2928 61.2613 41.2141 61.2132 41.1862 61.1397L41.1767 61.101L40.7819 57.7992C40.7706 57.7048 40.8428 57.6189 40.943 57.6076Z"
          fill="#06A0FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.0923 57.0391C37.1786 57.0443 37.2466 57.1053 37.2597 57.1824L37.2615 57.2221L37.0167 60.4504C37.0095 60.5453 36.9221 60.6174 36.8214 60.6113C36.7351 60.6061 36.6672 60.5452 36.654 60.468L36.6522 60.4283L36.897 57.2001C36.9042 57.1051 36.9916 57.033 37.0923 57.0391Z"
          fill="#06A0FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.5428 55.6232L33.585 55.6247C33.6846 55.6393 33.7522 55.7273 33.736 55.8214L33.6343 56.3123L33.0542 58.9681C33.0336 59.0615 32.9369 59.1219 32.8381 59.1032C32.7392 59.0845 32.6758 58.9937 32.6964 58.9004L33.3545 55.8747L33.3753 55.7686C33.3892 55.688 33.4601 55.6292 33.5428 55.6232Z"
          fill="#06A0FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.7604 54.1146C30.8398 54.1401 30.8881 54.2122 30.8829 54.2877L30.8731 54.3331L29.9242 56.9189C29.8911 57.0091 29.787 57.0574 29.6916 57.0269C29.6122 57.0014 29.5639 56.9293 29.5691 56.8537L29.5789 56.8084L30.5278 54.2226C30.5554 54.1474 30.6323 54.1014 30.7123 54.1058L30.7604 54.1146Z"
          fill="#06A0FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M89.5833 58.6239C89.1786 57.6514 88.803 56.1455 88.6829 53.8284C88.6908 53.7361 88.6979 53.6434 88.6979 53.5494V40.5517C88.6979 38.6426 87.0426 37.0955 85.0007 37.0955H61.6839C59.642 37.0955 57.9863 38.6426 57.9863 40.5517V53.5494C57.9863 55.4582 59.642 57.0057 61.6839 57.0057H83.7557C84.5203 58.0573 85.8996 59.3606 88.2673 59.9794C89.1395 60.2075 89.9119 59.4127 89.5833 58.6239Z"
          fill="#9CC5FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M65.4623 52.084C66.4879 52.084 66.7845 51.7585 66.7845 50.7615C66.7845 49.7593 66.4728 49.439 65.4623 49.439C64.4567 49.439 64.145 49.7593 64.145 50.7615C64.145 51.7585 64.4417 52.084 65.4623 52.084Z"
          fill="#5B88CE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M69.8617 52.084C70.8873 52.084 71.184 51.7585 71.184 50.7615C71.184 49.7593 70.8722 49.439 69.8617 49.439C68.8561 49.439 68.5444 49.7593 68.5444 50.7615C68.5444 51.7585 68.8411 52.084 69.8617 52.084Z"
          fill="#5B88CE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M74.2606 52.084C75.2862 52.084 75.5829 51.7585 75.5829 50.7615C75.5829 49.7593 75.2712 49.439 74.2606 49.439C73.2551 49.439 72.9434 49.7593 72.9434 50.7615C72.9434 51.7585 73.24 52.084 74.2606 52.084Z"
          fill="#5B88CE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M79.3346 48.5573L79.3707 47.7811C79.4293 46.5493 82.6217 47.8444 82.6217 44.4738C82.6217 41.8794 81.039 41.5039 78.6943 41.5039C77.5941 41.5039 76.4533 41.6431 75.583 41.8372L75.9392 43.3854C76.6021 43.263 77.4813 43.1702 78.4508 43.1702C80.0019 43.1702 80.4303 43.474 80.4303 44.4653C80.4303 46.718 77.5309 44.845 77.5309 47.2918C77.5309 47.3677 77.54 47.5533 77.5445 47.6335L77.5805 48.5573H79.3346Z"
          fill="#5B88CE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M78.6625 52.084C79.67 52.084 79.9823 51.7362 79.9823 50.7615C79.9823 49.7867 79.6599 49.439 78.6625 49.439C77.6601 49.439 77.3428 49.7867 77.3428 50.7615C77.3428 51.7362 77.65 52.084 78.6625 52.084Z"
          fill="#5B88CE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M96.4182 8H72.4534C70.3548 8 68.6535 9.60662 68.6535 11.5892V25.0868C68.6535 25.1844 68.6608 25.281 68.6688 25.3765C68.545 27.7827 68.1594 29.3469 67.7435 30.3564C67.4057 31.176 68.1996 32.001 69.0961 31.764C71.5292 31.1215 72.9471 29.768 73.733 28.676H96.4182C98.5168 28.676 100.218 27.069 100.218 25.0868V11.5892C100.218 9.60662 98.5168 8 96.4182 8Z"
          fill="#9CC5FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M77.7801 22.9885C78.8058 22.9885 79.1024 22.6631 79.1024 21.666C79.1024 20.6638 78.7907 20.3435 77.7801 20.3435C76.7746 20.3435 76.4629 20.6638 76.4629 21.666C76.4629 22.6631 76.7595 22.9885 77.7801 22.9885Z"
          fill="#5B88CE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82.1791 22.9885C83.2047 22.9885 83.5013 22.6631 83.5013 21.666C83.5013 20.6638 83.1896 20.3435 82.1791 20.3435C81.1735 20.3435 80.8618 20.6638 80.8618 21.666C80.8618 22.6631 81.1584 22.9885 82.1791 22.9885Z"
          fill="#5B88CE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M86.5785 22.9885C87.6041 22.9885 87.9007 22.6631 87.9007 21.666C87.9007 20.6638 87.589 20.3435 86.5785 20.3435C85.5729 20.3435 85.2612 20.6638 85.2612 21.666C85.2612 22.6631 85.5579 22.9885 86.5785 22.9885Z"
          fill="#5B88CE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M92.1214 19.4617L92.162 18.5885C92.2279 17.2027 95.8194 18.6597 95.8194 14.8677C95.8194 11.949 94.0389 11.5266 91.4011 11.5266C90.1633 11.5266 88.8799 11.6832 87.9009 11.9015L88.3016 13.6433C89.0473 13.5056 90.0365 13.4012 91.1271 13.4012C92.8722 13.4012 93.3541 13.7429 93.3541 14.8582C93.3541 17.3925 90.0923 15.2853 90.0923 18.038C90.0923 18.1234 90.1025 18.3322 90.1075 18.4224L90.1481 19.4617H92.1214Z"
          fill="#5B88CE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M90.9799 22.9885C91.9874 22.9885 92.2997 22.6408 92.2997 21.666C92.2997 20.6913 91.9773 20.3435 90.9799 20.3435C89.9775 20.3435 89.6602 20.6913 89.6602 21.666C89.6602 22.6408 89.9674 22.9885 90.9799 22.9885Z"
          fill="#5B88CE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M87.0908 89.7555C82.7269 85.2168 81.5457 80.5369 81.2228 78.2628C79.3177 78.84 77.4576 79.045 75.8673 78.6985C75.0705 84.7261 79.0518 89.369 81.159 91.493C83.4167 91.1908 85.3986 90.5437 87.0908 89.7555Z"
          fill="#06A0FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M101.79 61.4095C101.661 61.4174 101.528 61.3941 101.405 61.3358L98.4602 59.9456C98.0869 59.7692 97.9383 59.3405 98.1278 58.9872C98.3173 58.6346 98.773 58.4906 99.1463 58.6667L102.091 60.0568C102.464 60.2332 102.613 60.6622 102.423 61.0149C102.297 61.2513 102.051 61.3936 101.79 61.4095Z"
          fill="#0B7EFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M102.412 61.0441C102.412 61.0441 102.828 60.393 103.386 60.6464L97.9717 58.114C97.9717 58.114 98.4336 58.4637 98.114 59.0091L100.511 59.7702L102.412 61.0441Z"
          fill="#0B7EFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M87.072 79.8582C87.1729 79.8602 87.2523 79.9391 87.2493 80.0342L87.2415 80.1674L87.2237 80.342L87.1943 80.5538C87.1883 80.592 87.1818 80.6316 87.1746 80.6725C87.0915 81.1484 86.9535 81.6279 86.7473 82.0817C86.6709 82.2499 86.5861 82.4118 86.4926 82.5664C86.4424 82.6494 86.3308 82.6788 86.2432 82.6321C86.1556 82.5854 86.1253 82.4803 86.1754 82.3974C86.2619 82.2545 86.3405 82.1044 86.4116 81.9479C86.605 81.5222 86.7354 81.0691 86.814 80.6189L86.8325 80.5072L86.8602 80.3089L86.8768 80.1473L86.884 80.0267C86.8869 79.9315 86.9711 79.8561 87.072 79.8582Z"
          fill="#003CE5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M68.0389 80.8163C68.0926 80.8965 68.067 81.003 67.9816 81.0544L66.9911 81.6595C66.977 81.6685 66.9648 81.6765 66.9546 81.6832C66.8717 81.738 66.7578 81.7194 66.7003 81.6416C66.6427 81.5639 66.6633 81.4563 66.7462 81.4015L67.2487 81.0897L67.7868 80.7641C67.8722 80.7128 67.9851 80.7362 68.0389 80.8163Z"
          fill="#003CE5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M69.905 83.2327C69.9627 83.3105 69.9422 83.418 69.8594 83.4729L69.1632 83.9317L68.7884 84.1742C68.7044 84.2275 68.591 84.2067 68.5351 84.1278C68.4792 84.049 68.5021 83.9418 68.5862 83.8886L68.8657 83.7082L69.6506 83.1915C69.7334 83.1366 69.8473 83.155 69.905 83.2327Z"
          fill="#003CE5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M71.8462 85.3871C71.9097 85.4606 71.8975 85.5692 71.8191 85.6296L70.9724 86.2918C70.9517 86.3084 70.933 86.3236 70.9163 86.3373C70.8398 86.4 70.7244 86.3927 70.6586 86.3211C70.5927 86.2494 70.6012 86.1406 70.6777 86.0779L71.0848 85.7547L71.5892 85.3633C71.6676 85.3029 71.7827 85.3135 71.8462 85.3871Z"
          fill="#003CE5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.2743 64.2082C23.8643 64.4731 24.415 64.8218 24.9172 65.2619C27.3744 67.415 28.421 69.9445 28.0566 72.9007L28.0208 73.1626L27.659 73.1168C28.1025 70.1615 27.1116 67.6569 24.6674 65.5151C24.1926 65.0991 23.6725 64.7698 23.1152 64.5195C21.7963 63.9273 20.4474 63.8235 19.3427 63.9709L19.1968 63.9934L19.1256 63.6559L19.2942 63.6297C20.4636 63.4736 21.8815 63.5827 23.2743 64.2082Z"
          fill="#06A0FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.4779 89.6459C29.6346 89.8231 29.9601 90.0344 30.4126 90.2564L30.699 90.3906C30.7491 90.4132 30.8005 90.4357 30.853 90.4584L31.1813 90.5944C31.4089 90.6851 31.6534 90.7756 31.9123 90.8645L32.3109 90.9965C33.4688 91.3656 34.8604 91.6914 36.28 91.859C40.9125 92.4057 45.8178 91.5351 50.6904 88.8281L51.0468 88.6267L51.2352 88.9207C46.1725 91.8278 41.0572 92.7705 36.232 92.201C35.7179 92.1403 35.2073 92.0594 34.7094 91.9632L34.216 91.862C33.5647 91.7209 32.9412 91.5555 32.3669 91.3775L31.9459 91.2418C31.7403 91.173 31.5424 91.1028 31.3534 91.0319L30.9874 90.8893C30.1624 90.5551 29.5462 90.2138 29.2541 89.9281L29.1965 89.8678L29.4779 89.6459Z"
          fill="#06A0FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M56.4722 98.0813C56.5349 98.1404 56.5247 98.232 56.4495 98.286L56.2694 98.4098L56.1282 98.5035C55.9707 98.6069 55.7962 98.7179 55.6048 98.8358L55.1997 99.0803C54.1835 99.6819 53.0009 100.301 51.6636 100.905C47.0356 102.994 41.7779 104.374 36.0442 104.606C35.9463 104.61 35.8666 104.549 35.8662 104.469C35.8658 104.389 35.9448 104.321 36.0427 104.317C41.7167 104.087 46.9215 102.721 51.5031 100.653C52.8265 100.056 53.9962 99.4429 55.0003 98.8484L55.3993 98.6076C55.5249 98.5302 55.643 98.4559 55.7536 98.3849L56.1066 98.1525L56.2225 98.0721C56.2978 98.0181 56.4095 98.0223 56.4722 98.0813Z"
          fill="#06A0FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.3648 58.3962C53.4347 58.327 53.5504 58.324 53.6232 58.3894C55.0895 59.7074 56.0373 62.0248 56.5658 65.0667C56.948 67.2668 57.0781 69.6299 57.0424 71.8707L57.0283 72.4699L57.0108 72.9199L56.9972 73.1738C56.991 73.2688 56.9043 73.3417 56.8035 73.3365C56.7028 73.3313 56.6262 73.2501 56.6324 73.1551L56.6498 72.8134L56.663 72.462L56.6732 72.0746C56.6746 72.0071 56.6759 71.9383 56.677 71.868C56.7124 69.6459 56.5833 67.3019 56.2051 65.1249C55.6883 62.15 54.7656 59.8942 53.37 58.6398C53.2972 58.5743 53.2949 58.4653 53.3648 58.3962Z"
          fill="#06A0FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M80.8878 79.9482C80.9856 79.9241 81.085 79.9793 81.1097 80.0714C81.1345 80.1635 81.0753 80.2576 80.9774 80.2817C80.5278 80.3921 79.9912 80.4795 79.3848 80.5466C78.7354 80.6185 78.0494 80.6632 77.3766 80.687L76.7568 80.7031L76.3995 80.7067C76.378 80.7068 76.3588 80.7067 76.3418 80.7066C76.2409 80.7061 76.1601 80.6286 76.1614 80.5334C76.1627 80.4382 76.2455 80.3615 76.3465 80.362L76.7533 80.3585L76.87 80.3563C77.6883 80.3396 78.5463 80.2927 79.3448 80.2043C79.9358 80.1389 80.4566 80.0541 80.8878 79.9482Z"
          fill="#80DAFC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M81.5194 83.0795C81.6172 83.0555 81.7166 83.1106 81.7414 83.2027C81.7662 83.2948 81.7069 83.3889 81.6091 83.413C81.1623 83.5228 80.6289 83.6231 80.0265 83.7144C79.3658 83.8146 78.6574 83.8989 77.9481 83.9679L77.0778 84.0442L77.0009 84.0498C76.9002 84.0569 76.8131 83.9857 76.8063 83.8908C76.7996 83.7959 76.8757 83.7132 76.9764 83.7061L77.4462 83.6682L77.9133 83.6251C78.6162 83.5567 79.318 83.4731 79.9711 83.3742C80.5629 83.2844 81.0854 83.1862 81.5194 83.0795Z"
          fill="#80DAFC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M83.036 86.1256C83.1321 86.0957 83.2351 86.1448 83.266 86.2351C83.297 86.3255 83.2443 86.4229 83.1482 86.4528C82.7027 86.5915 82.167 86.7264 81.5597 86.857C80.9106 86.9965 80.2144 87.1238 79.5175 87.2364L79.0546 87.3088L78.5868 87.3768C78.4868 87.3903 78.3947 87.3248 78.3811 87.2306C78.3676 87.1364 78.4376 87.0491 78.5376 87.0356L78.9993 86.9686L79.4584 86.8967C80.1492 86.7851 80.8391 86.659 81.481 86.521C82.0779 86.3927 82.603 86.2604 83.036 86.1256Z"
          fill="#80DAFC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M85.2884 88.842C85.3794 88.8001 85.4885 88.8355 85.5322 88.921C85.576 89.0065 85.5376 89.1097 85.4467 89.1515C84.9987 89.3576 84.4556 89.5507 83.8372 89.731C83.1935 89.9187 82.5027 90.0829 81.8106 90.2227L81.3508 90.3118L80.9852 90.3768L80.8852 90.3932C80.7856 90.4089 80.6919 90.3456 80.6759 90.2517C80.66 90.1578 80.7279 90.069 80.8276 90.0533L81.0765 90.011L81.5035 89.9319C81.5792 89.9172 81.6569 89.9018 81.7366 89.8858C82.4188 89.7479 83.0995 89.5861 83.7316 89.4018C84.3334 89.2263 84.8595 89.0393 85.2884 88.842Z"
          fill="#80DAFC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M56.4854 87.3255C56.567 87.2689 56.6813 87.2851 56.7407 87.3617C56.8001 87.4382 56.782 87.5461 56.7004 87.6027L56.5082 87.7313C56.4726 87.7545 56.4353 87.7785 56.3963 87.8033L56 88.0485L55.5176 88.3352L54.7465 88.7793L53.9857 89.2072L52.4938 90.0267C52.4059 90.0741 52.2944 90.0457 52.2448 89.9631C52.1952 89.8806 52.2262 89.7753 52.3141 89.7279L53.0161 89.3452L53.8013 88.911C53.9306 88.8389 54.0589 88.7671 54.1854 88.6959L54.961 88.2549L55.3233 88.0446L55.6501 87.8515L55.9394 87.6765L56.1891 87.5208L56.3974 87.3853C56.4286 87.3645 56.4579 87.3445 56.4854 87.3255Z"
          fill="#80DAFC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M58.8192 90.0711C58.9009 90.0145 59.0151 90.0307 59.0745 90.1073C59.1339 90.1838 59.1158 90.2917 59.0342 90.3483L58.842 90.4769L58.608 90.6258L58.334 90.794L58.0216 90.9807L57.0802 91.5249L56.7048 91.7371L55.9272 92.1705L54.8273 92.7723C54.7394 92.8197 54.6279 92.7913 54.5783 92.7087C54.5287 92.6262 54.5597 92.5209 54.6476 92.4735L55.3497 92.0908L56.135 91.6566L57.1014 91.1115L57.6574 90.7901L57.9841 90.597L58.2733 90.4221L58.523 90.2664L58.7313 90.1309C58.7624 90.1101 58.7917 90.0901 58.8192 90.0711Z"
          fill="#80DAFC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M61.1741 93.1205C61.2335 93.197 61.2154 93.3049 61.1338 93.3615L61.043 93.4232L60.8296 93.5621L60.5753 93.7208L60.2818 93.8984L59.5843 94.3067L59.1798 94.538L58.419 94.9659L56.9269 95.7855C56.839 95.8329 56.7275 95.8045 56.6779 95.7219C56.6283 95.6394 56.6593 95.5341 56.7472 95.4867L57.4493 95.104L58.2346 94.6697C58.3639 94.5976 58.4922 94.5258 58.6188 94.4546L59.3942 94.0137L59.7566 93.8034L60.2328 93.5204L60.5027 93.355L60.732 93.2092L60.9189 93.0842C61.0005 93.0277 61.1148 93.0439 61.1741 93.1205Z"
          fill="#80DAFC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M62.7143 96.2288C62.7737 96.3054 62.7557 96.4133 62.6741 96.4698L62.4819 96.5985L62.2479 96.7474L61.9739 96.9156L61.4917 97.2023L60.7201 97.6466L60.3447 97.8588L59.5673 98.2922L58.4674 98.8939C58.3795 98.9413 58.2681 98.9128 58.2184 98.8303C58.1688 98.7478 58.1999 98.6425 58.2878 98.5951L58.9897 98.2124L59.7749 97.7783L60.7415 97.2331L61.1206 97.0147L61.4653 96.8129L61.7734 96.6288L62.0431 96.4634L62.2723 96.3177L62.459 96.1927C62.5406 96.1361 62.6549 96.1523 62.7143 96.2288Z"
          fill="#80DAFC"
        />
        <g clipPath="url(#clip1_1_4142)">
          <rect
            width="10.5935"
            height="10.5935"
            transform="translate(92.6113 63) rotate(25.8027)"
            fill="white"
            fillOpacity="0.01"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M96.6823 67.1747C98.256 67.9355 98.9396 69.777 98.2092 71.2877C97.9336 71.8577 97.496 72.2993 96.9734 72.5872L97.5353 74.1001C97.6471 74.4012 97.4901 74.726 97.1846 74.8254C96.8982 74.9186 96.583 74.7807 96.4526 74.5149L96.429 74.4601L95.8676 72.9471C95.2669 73.0247 94.6312 72.9325 94.0374 72.6454C92.4637 71.8846 91.7801 70.0432 92.5105 68.5325C93.2409 67.0218 95.1087 66.4139 96.6823 67.1747ZM96.1867 68.2004C95.2031 67.7248 94.0358 68.1048 93.5793 69.049C93.1228 69.9932 93.55 71.1441 94.5336 71.6196C95.5171 72.0951 96.6845 71.7151 97.1409 70.771C97.5974 69.8268 97.1702 68.6759 96.1867 68.2004Z"
            fill="#0A47F0"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M93.893 66.5613C93.7658 66.4998 93.7107 66.3514 93.7696 66.2297C93.8057 66.1537 94.1033 65.464 93.3503 65.1C93.2236 65.0387 93.1685 64.8903 93.2275 64.7681C93.2862 64.6469 93.4369 64.5974 93.5636 64.6587C94.3161 65.0225 94.6715 64.363 94.7099 64.2848C94.769 64.1638 94.919 64.1157 95.0458 64.1769C95.1707 64.2373 95.2264 64.3832 95.171 64.5038L95.1707 64.5043L95.1705 64.5047L95.1703 64.5052L95.1701 64.5056L95.1699 64.5061L95.1696 64.5065L95.1694 64.507C95.1324 64.5858 94.8358 65.2737 95.5882 65.6376C95.715 65.6988 95.7698 65.8477 95.7112 65.9689C95.6522 66.0911 95.5016 66.1401 95.3749 66.0788C94.6145 65.7112 94.2662 66.3752 94.2291 66.4518C94.1703 66.5735 94.0198 66.6225 93.893 66.5613Z"
            fill="#0A47F0"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M91.5255 67.6229C91.4301 67.5768 91.3888 67.4655 91.4329 67.3742C91.46 67.3172 91.6832 66.8 91.1184 66.5269C91.0234 66.481 90.9821 66.3697 91.0264 66.2781C91.0703 66.1871 91.1834 66.15 91.2784 66.196C91.8428 66.4689 92.1093 65.9742 92.1381 65.9155C92.1824 65.8248 92.295 65.7887 92.3901 65.8347C92.4837 65.8799 92.5255 65.9894 92.4839 66.0798L92.4838 66.0802L92.4836 66.0805L92.4834 66.0808L92.4833 66.0812L92.4831 66.0815L92.4829 66.0818L92.4828 66.0822C92.455 66.1413 92.2325 66.6573 92.7969 66.9301C92.892 66.9761 92.9331 67.0877 92.8891 67.1787C92.8448 67.2703 92.7319 67.307 92.6369 67.2611C92.0666 66.9853 91.8053 67.4834 91.7776 67.5408C91.7334 67.6321 91.6205 67.6689 91.5255 67.6229Z"
            fill="#0A47F0"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1_4142">
          <rect width="106" height="110" fill="white" />
        </clipPath>
        <clipPath id="clip1_1_4142">
          <rect
            width="10.5935"
            height="10.5935"
            fill="white"
            transform="translate(92.6113 63) rotate(25.8027)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
