import { FC } from "react";

interface CheckMarkIconProps {
  className?: string;
}

export const CheckMarkIcon: FC<CheckMarkIconProps> = ({ className }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2C5.582 2 2 5.582 2 10C2 14.418 5.582 18 10 18C14.418 18 18 14.418 18 10C18 5.582 14.418 2 10 2ZM10 0C12.652 0 15.195 1.053 17.071 2.929C18.946 4.804 20 7.348 20 10C20 15.523 15.522 20 10 20C4.478 20 0 15.523 0 10C0 4.477 4.478 0 10 0ZM6.70711 10.2929C6.31658 9.90237 5.68342 9.90237 5.29289 10.2929C4.90237 10.6834 4.90237 11.3166 5.29289 11.7071L7.79289 14.2071C8.18342 14.5976 8.81658 14.5976 9.20711 14.2071L14.7071 8.70711C15.0976 8.31658 15.0976 7.68342 14.7071 7.29289C14.3166 6.90237 13.6834 6.90237 13.2929 7.29289L8.5 12.0858L6.70711 10.2929Z"
        fill="#6AC90F"
      />
    </svg>
  );
};

export default CheckMarkIcon;
