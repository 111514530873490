import * as Sentry from "@sentry/react";

export const logException = (
  error: unknown,
  logToSentry: boolean = true,
  logToConsole: boolean = true,
) => {
  if (logToSentry) {
    Sentry.captureException(error);
  }
  if (logToConsole) {
    console.error(error);
  }
};

export const logMessage = (
  message: string,
  logToSentry: boolean = true,
  logToConsole: boolean = true,
) => {
  if (logToSentry) {
    Sentry.captureMessage(message);
  }
  if (logToConsole) {
    console.log(message);
  }
};
