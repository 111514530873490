import { FC } from "react";

interface SearchIconProps {
  className?: string;
}

export const SearchIcon: FC<SearchIconProps> = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect width="24" height="24" fill="white" fillOpacity="0.01" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4201 4.5C16.3801 4.5 19.5903 7.58177 19.5903 11.3833C19.5903 12.8179 19.1332 14.1499 18.3511 15.2526L20.989 17.7843C21.5141 18.2883 21.5141 19.1055 20.989 19.6095C20.4968 20.082 19.7179 20.1115 19.1899 19.6981L19.0878 19.6095L16.4506 17.0771C15.302 17.8278 13.9145 18.2667 12.4201 18.2667C8.46018 18.2667 5.25 15.1849 5.25 11.3833C5.25 7.58177 8.46018 4.5 12.4201 4.5ZM12.4201 7.08124C9.94517 7.08124 7.93881 9.00735 7.93881 11.3833C7.93881 13.7593 9.94517 15.6854 12.4201 15.6854C14.8951 15.6854 16.9015 13.7593 16.9015 11.3833C16.9015 9.00735 14.8951 7.08124 12.4201 7.08124Z"
        fill="url(#paint0_linear_140_589)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.12559 6C5.80547 6 5.54678 5.75161 5.54678 5.44537C5.5456 5.25482 5.47236 3.55463 3.57763 3.55463C3.25869 3.55463 3 3.30624 3 2.99887C3 2.69376 3.25869 2.44423 3.57763 2.44423C5.47118 2.44423 5.5456 0.748582 5.54678 0.551229C5.54796 0.246125 5.80665 0 6.12559 0C6.4398 0 6.69732 0.242722 6.70322 0.543289V0.544423V0.545558V0.546692V0.547826V0.54896V0.550094V0.551229C6.70558 0.748582 6.77882 2.44423 8.67237 2.44423C8.99131 2.44423 9.25 2.69376 9.25 2.99887C9.25 3.30624 8.99131 3.55463 8.67237 3.55463C6.75874 3.55463 6.70322 5.25255 6.70322 5.44537C6.70322 5.75161 6.44453 6 6.12559 6Z"
        fill="#0A44E5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.34419 10.5C2.1041 10.5 1.91008 10.3137 1.91008 10.084C1.9092 9.94112 1.85427 8.66597 0.433224 8.66597C0.194021 8.66597 0 8.47968 0 8.24915C0 8.02032 0.194021 7.83318 0.433224 7.83318C1.85338 7.83318 1.9092 6.56144 1.91008 6.41342C1.91097 6.18459 2.10499 6 2.34419 6C2.57985 6 2.77299 6.18204 2.77742 6.40747V6.40832V6.40917V6.41002V6.41087V6.41172V6.41257V6.41342C2.77919 6.56144 2.83412 7.83318 4.25428 7.83318C4.49348 7.83318 4.6875 8.02032 4.6875 8.24915C4.6875 8.47968 4.49348 8.66597 4.25428 8.66597C2.81906 8.66597 2.77742 9.93941 2.77742 10.084C2.77742 10.3137 2.5834 10.5 2.34419 10.5Z"
        fill="#0A41DA"
      />
      <defs>
        <linearGradient
          id="paint0_linear_140_589"
          x1="5.75415"
          y1="7.23309"
          x2="21.8928"
          y2="18.2654"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0A47F0" />
          <stop offset="0.8" stopColor="#06298A" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SearchIcon;
