import React from "react";
import { Checkbox } from "primereact/checkbox";

interface CustomChecklistItemProps {
  label: string;
  isSelected: boolean;
  onClick: () => void;
}

const CustomChecklistItem: React.FC<CustomChecklistItemProps> = ({
  label,
  isSelected,
  onClick,
}) => {
  return (
    <div
      className="flex w-full items-center justify-between border-b mx-3 py-4 cursor-pointer"
      onClick={onClick}
    >
      <span className="text-gray-800 text-[16px]">{label}</span>
      <Checkbox
        checked={isSelected}
        className="p-checkbox p-component"
        inputId={`custom-checkbox-${label}`}
      />
    </div>
  );
};

export default CustomChecklistItem;
